<h4>{{'DEV.SELECT'|translate}}</h4>

<ng-container *ngIf="data">
    <mat-form-field>
      <mat-label>{{'DEV.AUD'|translate}}</mat-label>
      <mat-select matNativeControl required [(ngModel)]="data.actual.audio">
        <mat-option *ngFor="let device of data.inputs.audio" [value]="device.deviceId">
          {{device.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field>
      <mat-label>{{'DEV.VID'|translate}}</mat-label>
      <mat-select matNativeControl required [(ngModel)]="data.actual.video">
        <mat-option *ngFor="let device of data.inputs.video" [value]="device.deviceId">
          {{device.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
</ng-container>

<br>

<button mat-button (click)="cancel()">{{'DEV.CANCEL'|translate}}</button>
<button mat-button color="primary" *ngIf="data.actual.audio && data.actual.video" (click)="save()">{{'DEV.SAVE'|translate}}</button>
