<div [ngClass]="{'publishing': publishingVideo && !publishingScreen, 'publishingVideo': publishingVideo && publishingScreen}"
    #publisherVideoDiv></div>
<div [ngClass]="{'publishing': !publishingVideo && publishingScreen, 'publishingScreen': publishingVideo && publishingScreen}"
    #publisherScreenDiv></div>

<ng-container *ngIf="publishingVideo">
    <div class="publisher-controls">
        <button *ngIf="ots.hasPermission('ManageArchives') && !ots.recordingSession" title="{{'TIT.START'|translate}}"
            class="controls-button btn-record-archive" (click)="ots.startArchive()">
            <fa-icon [icon]="faCircle"></fa-icon>
        </button>
        <button *ngIf="ots.hasPermission('ManageArchives') && ots.recordingSession" title="{{'TIT.STOP'|translate}}"
            class="controls-button btn-record-archive btn-record-archive-active" (click)="ots.stopArchive()">
            <fa-icon [icon]="faStop"></fa-icon>
        </button>
        <button (click)="toggleVideo()" title="{{'TIT.CAM_OFF'|translate}}"
            *ngIf="publisherVideo.stream !== undefined && publisherVideo.stream.hasVideo; else elseVideo"
            class="controls-button">
            <fa-icon [icon]="faVideo"></fa-icon>
        </button>
        <ng-template #elseVideo>
            <button (click)="toggleVideo()" class="btn-red controls-button " title="{{'TIT.CAM_ON'|translate}}">
                <fa-icon [icon]="faVideoSlash"></fa-icon>
            </button>
        </ng-template>
        <button (click)="toggleAudio()"
            *ngIf="publisherVideo.stream !== undefined && publisherVideo.stream.hasAudio; else elseAudio" title="{{'TIT.MIC_OFF'|translate}}"
            class="controls-button">
            <fa-icon [icon]="faMicrophone"> </fa-icon>
        </button>
        <ng-template #elseAudio>
            <button (click)="toggleAudio()" class="controls-button btn-red" title="{{'TIT.MIC_ON'|translate}}">
                <fa-icon [icon]="faMicrophoneSlash"></fa-icon>
            </button>
        </ng-template>
        <!--
        <button (click)="selectDevices()" class="controls-button">
            <fa-icon [icon]="faCog"></fa-icon>
        </button>
        -->
        <button (click)="hangUp()" class="controls-button btn-red btn-hangup" title="{{'TIT.FINISH_CALL'|translate}}">
            <fa-icon [icon]="faPhoneSlash"></fa-icon>
        </button>

        <!--
        <div style="position: relative; display: inline-block;">
            <div style="position: absolute; right: 5px; top: 5px;" *ngIf="chatMessageNumber !== 0">
                {{chatMessageNumber}}
            </div>
            <button (click)="chatView()" class="controls-button btn-hangup btn-hangup material-icons" title="Chat">
                <span class="material-icons">
                    chat
                </span>
            </button>
        </div>

        <button (click)="screenSharing()" *ngIf="screenCap" class="controls-button" title="{{'TIT.SHARE'|translate}}">
            <span class="material-icons">
                {{'TIT.CAST'|translate}}
            </span>
        </button>
        -->
    </div>
    <hr style="display:none">

    <div class="msg">
        <!-- <div *ngIf="message[0] !== undefined">
            <p class="wb msg-autor">{{message[0][1]}}</p>
            <p class="wb msg-text" *ngIf="message[0][3] !== 'file'">{{message[0][0]}}</p>
            <p class="wb msg-text" *ngIf="message[0][3] !== 'text'">
                <a *ngIf="!ots.hasPermission('AttachFilesChat')" href='{{message[0][5]}}' target="_blank" (click)="deleteFile(message[0][4])">
                    <span class='material-icons'>attach_file</span>
                    Fichero Adjunto
                </a>
                <span *ngIf="ots.hasPermission('AttachFilesChat')">Fichero enviado</span>
            </p>

        </div> -->

        <div *ngIf="message[0] !== undefined">
            <p class="wb msg-autor">{{message[0].username}}</p>
            <p class="wb msg-text">{{message[0].msg}}</p>
        </div>
    </div>

    <div class="chat-div" [ngClass]="{'hidden': !chatIsView}">

        <div class="chat-top-bar">
            <button (click)="chatView()" class="red-button" title="Cerrar chat">X</button>
        </div>

        <div #scroll class="scroll">
            <div *ngFor="let m of messages" class="chat"
                [ngClass]="{'chat-left': m.sameUser === false, 'chat-right': m.sameUser === true}">
                <div class="bocadillo">
                    <p class="wb author-name">{{m.username}}</p>
                    <p class="wb msg-text" style="margin-bottom: 2rem;white-space:pre-wrap;" *ngIf="m.type == 'text'">{{m.msg}}</p>
                    <p class="wb msg-text" *ngIf="m.type == 'file'">
                        <a *ngIf="!['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(m.extension); else enlaceDescarga" href='{{m.signedUrl}}' download="{{m.name}}.{{m.extension}}" target="_blank">
                            <span class='material-icons'>attach_file</span>
                            {{m.name}}.{{m.extension}}
                        </a>
                        <ng-template #enlaceDescarga>
                            <img src="{{m.signedUrl}}" class="img-full-width" alt="">
                        </ng-template>
                    </p>
                </div>
            </div>
        </div>

        <div id="dropzoneElement" class="textarea-div">

            <button title="Enviar archivo" [attr.disabled]="textoBotonEnviar == 'autorenew' ? true : null" *ngIf="ots.hasPermission('AttachFilesChat')" (dragleave)="dragOverControl(false)" (dragover)="dragOverControl(true)" [ngClass]="{'dragOn': dragOver == true}" class="button-file" (click)="file.click()">
                <label for="ficheroSubida">
                    <span #buttonSendFile [ngClass]="{'spin': textoBotonEnviar == 'autorenew'}"
                    class="material-icons">{{textoBotonEnviar}}<input id="ficheroSubida" #file type="file" (change)="fileEvent($event)" /></span>
                </label>
            </button>


            <textarea #textareaChat (ngModel)="textareaChat" (keyup.enter)="prepareMessage(textareaChat)"
                class="textarea"></textarea>
            <button class="button-send" (click)='prepareMessage(textareaChat)' title="{{'TIT.SEND_MSG'|translate}}"><span
                    class="material-icons">{{'TIT.SEND'|translate}}</span>
            </button>
        </div>

    </div>

</ng-container>
