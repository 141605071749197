<div #subscriberDiv></div>
<div class="subscriber-controls">

    <ng-container *ngIf="ots.hasPermission('ManageStreams')">
        <button class="controls-button" (click)="cycleParticipantVideo()" title="{{'MED.VID'|translate}}"><fa-icon [icon]="faSyncAlt"></fa-icon> <fa-icon [icon]="faVideo"></fa-icon></button>
        <button class="controls-button" (click)="cycleParticipantAudio()" title="{{'MED.AUD'|translate}}"><fa-icon [icon]="faSyncAlt"></fa-icon> <fa-icon [icon]="faMicrophone"></fa-icon></button>
        
        <button *ngIf="ots.hasPermission('StartStethoscope')" (click)="startStetho()" class="controls-button" title="{{'MED.EST'|translate}}"><fa-icon [icon]="faStethoscope"></fa-icon></button>
        
        <button *ngIf="takingSnapshot === false; else takingSnapshotElement" (click)="captureSnapshot()" class="controls-button" title="{{'MED.SS'|translate}}"><fa-icon [icon]="faCamera"></fa-icon></button>
        <ng-template #takingSnapshotElement>
            <button disabled class="controls-button spin"><fa-icon class="spin" [icon]="faSpinner"></fa-icon></button>
        </ng-template>
        
        <button (click)="toggleAudio()" *ngIf="subscriber && subscriber.stream && subscriber.stream.hasAudio; else elseAudio" title="{{'MED.MUTE'|translate}}" class="mute controls-button">
            <fa-icon [icon]="faMicrophone"></fa-icon>
        </button>
        <ng-template #elseAudio>
            <button (click)="toggleAudio()" class="controls-button btn-red mute"><fa-icon [icon]="faMicrophoneSlash" title="{{'MED.UNMUTE'|translate}}"></fa-icon></button>
        </ng-template>
        
        <!-- <button class="controls-button" (click)="changeParticipantDevices()"><fa-icon [icon]="faCog"></fa-icon></button> -->
    </ng-container>

</div>