import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { PublisherComponent } from './videoapp/components/publisher/publisher.component';
import { SubscriberComponent } from './videoapp/components/subscriber/subscriber.component';
import { OpentokService } from './opentok.service';
import { VideoAppComponent } from './videoapp/videoapp.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DeviceselectorComponent } from './videoapp/components/deviceselector/deviceselector.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ModalavisoComponent } from './videoapp/components/modalaviso/modalaviso.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


const modules = [
  MatDialogModule,
  MatButtonModule,
  MatInputModule,
  MatFormFieldModule,
  MatSelectModule
];

@NgModule({
imports: [...modules],
exports: [...modules],
declarations: [/* ModalavisoComponent */]
,
})export class MaterialModule {};

@NgModule({
  declarations: [
    AppComponent,
    VideoAppComponent,
    PublisherComponent,
    SubscriberComponent,
    DeviceselectorComponent,
    ModalavisoComponent
  ],
  entryComponents: [
    DeviceselectorComponent,
    ModalavisoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: navigator.language || 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [OpentokService],
  bootstrap: [AppComponent]
})
export class AppModule { }
