<div *ngIf="session">

 <!--  <div class="landscape-overlay" *ngIf="ots.userConfig.portraitMsg">
    <span>{{ots.userConfig.portraitMsg}}</span>
  </div> -->

  <div id="screen-{{ots.userData.role}}" *ngIf="currentState != 'disconnected' && currentState != 'disconnecting'; else disconnected">
    <div id="textos-inicio">
      <h1>{{'GEN.WELCOME'|translate}} {{ots.userData.name}} </h1>
      <div *ngIf="ots.hasPermission('ManageArchives')" style="display: none;">
        <span>{{'GEN.ARCHIVES'|translate}}</span>
        <br>
        <button (click)="startArchive()">{{'GEN.START'|translate}}</button>
        <button (click)="stopArchive()">{{'GEN.STOP'|translate}}</button>
      </div>

      <h2 *ngIf="streams.length == 0" translate>{{ots.userConfig.emptyMsg||'GEN.NOBODY'}}</h2>
      <h2 *ngIf="getSpectatorNames()">{{getSpectatorNames()}} {{'GEN.SPECTATOR'|translate}}</h2>
    </div>
    <div *ngIf="ots.hasPermission('CreateStreams')">
      <app-publisher *ngIf="doPublish" [session]="session"></app-publisher>
    </div>
    <app-subscriber *ngFor="let stream of streams" [className]="'role_'+stream.role" [ngClass]="{'user': screen && stream.strm.videoType === 'camera', 'screen': screen && stream.strm.videoType === 'screen'}" [stream]="stream.strm" [session]="session"></app-subscriber>
  </div>
  <ng-template #disconnected>
    <div id="textos-inicio">
      <h2 translate>{{'GEN.CLOSE_CALL'}}</h2>
    </div>
  </ng-template>

  <button *ngIf="checkFullscreen(); else fullscreenOff" class="fullscreen-btn controls-button fullscreen-btn-active" (click)="toggleFullscreen()" >
    <fa-icon [icon]="faCompress"></fa-icon>
  </button>
  <ng-template #fullscreenOff>
    <button class="fullscreen-btn controls-button " (click)="toggleFullscreen()">
      <fa-icon [icon]="faExpand"></fa-icon>
    </button>
  </ng-template>
</div >

<div *ngIf="!session">
  <h1>{{'GEN.LOADING'|translate}}</h1>
</div>

<br><br><br>
