import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {

    constructor(private http: HttpClient) { }

    URL_UPLOAD = "https://everhealth.portal-medico.es/api/videoapp/upload_file";

    sendFile(file, hash) {

        var formData: FormData = new FormData();

        formData.append("file", file, file.name);
        formData.append("hash", hash);

        return this.http.post(this.URL_UPLOAD, formData);

    }

}
