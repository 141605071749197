import { Component, ElementRef, AfterViewInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { OpentokService } from '../../../opentok.service';
import { FileUploadService } from '../../../file-upload.service';
import { faVideo, faSyncAlt, faVideoSlash, faMicrophone, faMicrophoneSlash, faPhoneSlash, faCircle, faStop, faCog } from '@fortawesome/free-solid-svg-icons';
import { DeviceselectorComponent } from '../deviceselector/deviceselector.component';

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.css']
})

export class PublisherComponent implements AfterViewInit, OnDestroy {

  @ViewChild('publisherVideoDiv', { static: true }) publisherVideoDiv: ElementRef;
  @ViewChild('publisherScreenDiv', { static: true }) publisherScreenDiv: ElementRef;
  @ViewChild("buttonSendFile") buttonFile:ElementRef;
  @Input() session: OT.Session;

  //video chat
  publisherVideo: OT.Publisher;
  publishingVideo: Boolean;

  //screen chat
  publisherScreen: OT.Publisher;
  publishingScreen: Boolean;
  screenCap: Boolean;

  // muestra los iconos
  faVideo = faVideo;
  faVideoSlash = faVideoSlash;
  faMicrophone = faMicrophone;
  faMicrophoneSlash = faMicrophoneSlash;
  faPhoneSlash = faPhoneSlash;
  faCircle = faCircle;
  faSyncAlt = faSyncAlt;
  faStop = faStop;
  faCog = faCog;
  messageData: object;

  //chat
  messages: any[] = [];
  message: any[] = [];
  @ViewChild('scroll') private scroll: ElementRef;
  chatIsView: boolean = false;
  file: any;
  @ViewChild('file') private inputfile: ElementRef;
  textoBotonEnviar:string = 'attach_file';

  // Dropzone
  dragOver: Boolean = false;

  //constructor
  constructor(public ots: OpentokService, public matDialog: MatDialog, public uploadFile: FileUploadService) {
    this.publishingVideo = false;
    this.publishingScreen = false;
    this.screenCap = false;

  }

  //al inicio
  ngAfterViewInit() {


    const OT = this.ots.getOT();

    //inicia el video chat
    this.publisherVideo = OT.initPublisher(this.publisherVideoDiv.nativeElement, { insertMode: 'append', fitMode: 'contain', style: { buttonDisplayMode: 'off' } });

    //si se ha conectado (entiendo) inicia la conexion con la api para enviar mensajes
    if (this.session) {
      if (this.session['isConnected']()) {
        this.publish();
      }
      this.session.on('sessionConnected', () => this.publish());
    }

    //envia señal de que el usuario no ha dado permisos para la camara

    // if (!ots.hasPermission('ManageStreams'))
    if (this.ots.userConfig.MustSendSignalIfPermissionsDenied) {
      this.publisherVideo.on('accessDenied', () => {
        setTimeout(() => {
          this.session.signal({
            type: 'permissionsDenied',
            // to: event.from, como no conocemos el stram del médico lo enviamos a todos
            data: this.publisherVideo.id + ""
          }, err => err && console.error(err));
        }, 5000)

      });
    }

    //comprueba si se puede compartir pantalla
    let es = false;
    OT.checkScreenSharingCapability(function (response) {
      if (!response.supported || response.extensionRegistered === false) {
        es = false;
      } else if (response.extensionInstalled === false) {
        console.log("necesita extension");
      } else {
        es = true;
      }
    });
    this.screenCap = es;

  }

  //cuando destruyes la sesion, cierra las conexiones
  ngOnDestroy(): void {
    this.session.unpublish(this.publisherVideo);
    this.session.unpublish(this.publisherScreen);
  }

  //cuando ya ha conectado el chat de video
  publish() {
    this.session.publish(this.publisherVideo, (err) => {
      if (err && err.name == 'OT_USER_MEDIA_ACCESS_DENIED') {
        //alert(JSON.stringify(err));
        alert('Por favor, revise los permisos de audio, vídeo y ubicación para poder realizar la videoconsulta, ¡gracias!');
      }
      else if (err && err.name == 'OT_TIMEOUT') {
        alert('No se ha podido conectar debido a una mala calidad de conexión');
      }
      else {
        this.publishingVideo = true;

        this.selectDevices();

        this.publisherVideo.publishVideo(this.ots.userConfig.AutostartPublisherVideo);
        this.publisherVideo.publishAudio(this.ots.userConfig.AutostartPublisherAudio);

        this.session.on('signal', event => {
          if (event.type.endsWith("permissionsDenied")) {

            // TODO: handle permissions message
            if (this.ots.hasPermission('ManageStreams')) { // es el medico

              alert('El usuario no ha aceptado todos los permisos de su dispositivo, comuníqueselo para poder realizar la videoconsulta');
            }
          }

          if (event.type.endsWith("videoDeshabilitadoPaciente")) {

            alert('Se ha perdido la conexión debido a la baja calidad en la red, por favor, intente conectarse de nuevo');
          }

          if (event.type.endsWith("fetch_deviceList")) {
            OT.getDevices((err, devices) => {
              if (!err) {
                this.session.signal({
                  type: 'fetch_deviceListResponse',
                  to: event.from,
                  data: JSON.stringify(this.generateDevicesData(devices))
                }, err => err && console.error(err));
              }
            });
          }
          if (event.type.endsWith("fetch_deviceListResponse")) {
            /* DeviceselectorComponent.Open(this.matDialog, JSON.parse(event.data), res=>{
              this.session.signal({
                type: 'moderate_setDevices',
                to: event.from,
                data: JSON.stringify(res)
              }, err=>err&&console.error(err));
            }); */
          }

          //acciones del moderador para activar/desctivar cosas
          if (event.type.endsWith("moderate_setDevices")) {
            this.setDevices(JSON.parse(event.data));
          }
          if (event.type.endsWith("moderate_cycleVideoStream")) {
            this.cycleVideo();
          }
          if (event.type.endsWith("moderate_cycleAudioStream")) {
            this.cycleAudio();
          }
          if (event.type.endsWith("moderate_toggleAudioStream")) {
            this.toggleAudio();
          }
          if (event.type.endsWith("moderate_startStetho")) {
            (<any>window).location.href = "stethoscope:slave?room=" + this.ots.sessionData.Session.Id;
          }

          //chat
          if (event.type.endsWith("msgchat")) {
            this.chatWrite(event);
          }
        });
      }
    });
  }

  //activa/desactiva audio
  toggleAudio() {
    this.publisherVideo.publishAudio(!this.publisherVideo.stream.hasAudio)
  }

  //activa/desactiva video
  toggleVideo() {
    this.publisherVideo.publishVideo(!this.publisherVideo.stream.hasVideo)
  }

  //cambia opciones del video
  cycleVideo = () => this.publisherVideo.cycleVideo();

  //cambia opciones del audio
  cycleAudio() {
    // Cycling through microphone inputs
    OT.getDevices((err, devices) => {
      if (!err) {
        let currentIndex = 0;
        let audioInputs = devices.filter((device) => device.kind === 'audioInput');
        // Find the right starting index for cycleMicrophone
        audioInputs.forEach((device, idx) => {
          if (device.label === this.publisherVideo.getAudioSource().label) {
            currentIndex = idx;
          }
        });

        let device = audioInputs[(currentIndex + 1) % audioInputs.length];
        this.publisherVideo.setAudioSource(device.deviceId);
        console.log('Changed audio source [' + (currentIndex + 1) + "/" + audioInputs.length + "]", device.label, device.deviceId);
      } else {
        console.error(err);
      }
    });

  }


  generateDevicesData(devices) {
    return {
      inputs: {
        audio: devices.filter((device) => device.kind === 'audioInput'),
        video: devices.filter((device) => device.kind === 'videoInput')
      },
      actual: {
        audio: null,
        video: null
      }
    };
  }

  selectDevices() {
    OT.getDevices((err, devices) => {
      if (!err) {
        //DeviceselectorComponent.Open(this.matDialog, this.generateDevicesData(devices), res=>this.setDevices(res));
      } else {
        console.error(err);
      }
    });
  }

  //cambia el dispositivo
  setDevices(res) {
    if (res) {
      console.log('Changing devices', res);
      this.publisherVideo.setAudioSource(res.audio);
      this.setVideoSource(res.video);
    }
  }

  //cambia la entrada de video
  async setVideoSource(video: string) {
    let lastDevice = '';
    for (let i = 0; i < 10 && lastDevice != video; i++) {
      lastDevice = (await this.publisherVideo.cycleVideo()).deviceId;
    }
  }

  //cierra sesion
  hangUp() {

    let sess = this.session;
    this.session.on("sessionDisconnected", function (event) {
      //console.log(sess);

    });
    this.session.disconnect();

  }

  screenSharing() {

    if (!this.publishingScreen) {

      this.publishingScreen = true;

      this.publisherScreen = OT.initPublisher(this.publisherScreenDiv.nativeElement, { videoSource: "screen", publishAudio: false, insertMode: 'append', fitMode: 'contain', style: { buttonDisplayMode: 'off' } });

      this.session.publish(this.publisherScreen, (err) => {
        if (err.name === 'OT_USER_MEDIA_ACCESS_DENIED'){
          this.publishingScreen = false;
        }
      });

    } else {
      this.publishingScreen = false;
      this.publisherScreen.destroy();
    }
  }

  //funcion del chat, al recibir el mensaje, lo muestra
  chatWrite(event) {

    let data = JSON.parse(event.data);

    let signedUrl = null;
    let extension = null;
    let name = null;

    // Si recibimos un fichero, establecemos los datos relativos al mismo
    if (data.type === 'file') {
      signedUrl = data.signedUrl;
      extension = data.extension;
      name = data.name;
    }

    // Guardamos en el array de los mensajes los datos
    this.messages.push({
        msg: data.msg,
        username: data.username,
        type: data.type,
        signedUrl: signedUrl,
        extension: extension,
        name: name,
        sameUser: data.role == this.ots.sessionData.Session.UserData.role ? true : false
    });

    // Hacemos diferenciación entre quien lo recibe y quien lo ha enviado
    /* if (data.role !== this.ots.sessionData.Session.UserData.role) {
      this.messages.push([data.msg, data.username, 1, data.type, key, locale]);
    } else {
      this.messages.push([data.msg, data.username, 0, data.type, key, locale]);
    } */

    // Hacer scroll en el chat
    if (!this.chatIsView) {
      this.chatDisabled(data);
    } else {
      setTimeout(() => {
        try {
          this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
        } catch (err) { }
      }, 250);
    }
  }

  //si el chat esta desabilitado
  chatDisabled(data) {

    let key = null;
    let locale = null;

    //si es fichero, le agrega los datos
    if (data.type === 'file') {
      key = data.file.key;
      locale = data.file.Location;
    }

    //esta variable, me ayuda al borrar, debido a que nunca es la misma
    var time = Date.now();

    this.message[0] = {
      msg: data.type == "text" ? data.msg : "Ha enviado un archivo",
      username: data.username,
      type: data.type,
      sameUser: data.role == this.ots.sessionData.Session.UserData.role ? true : false,
      time: time
    };

    //si no hay mensaje 0
    /* if (this.message[0] === undefined) {
      this.message[0] = [data.msg, data.username, time, data.type, key, locale];

      //si hay mensaje 0 pero no 1
    } else if (this.message[1] === undefined) {
      this.message[1] = [data.msg, data.username, time, data.type, key, locale];

      //si los dos estan ocupados
    } else {
      this.message[0] = this.message[1];
      this.message[1] = [data.msg, data.username, time, data.type, key, locale];
    } */

    //al cabo de 5 segundos, repaso todas los mensajes, y si tiempo es igual, lo borro
    setTimeout(() => {
      for (let i = 0; i < this.message.length; i++) {
        if (this.message[i].time === time) {
          this.message.splice(i, 1);
        }
      }
    }, 5000);
  }

  //activa/desactiva el chat
  chatView() {
    this.chatIsView = !this.chatIsView;
  }

  //almacena el fichero en la variable file
  public fileEvent($event) {

    // Si hay fichero seleccionado
    if($event.target.files[0]) {

      // Modificamos la variable del listener para añadir el loader al botón
      this.textoBotonEnviar = 'autorenew';

      // Llamamos al servicio para realizar la petición post
      var estado = this.uploadFile.sendFile($event.target.files[0], this.ots.sessionData.Session.Hash);

      // Nos suscribimos al servicio y esperamos a la respuesta (interior de la función)
      estado.subscribe(response => {
        
        // Procesamos la respuesta obtenida del servidor
        this.processResponse(response);

        // Limpiamos el fichero del input
        this.inputfile.nativeElement.value = "";

      });

    }

    //if(estado.resuelto !== undefined && estado.resuelto === "OK") {



    //}

    // Instanciamos lector del fichero
    /* const reader = new FileReader();
    reader.readAsDataURL($event.target.files[0]);
    
    reader.onload = () => {
        
        // Creamos la variable con los datos del fichero
        this.file = {
            type: "file",
            data: reader.result,
            username: this.ots.sessionData.Session.UserData.name,
            role: this.ots.sessionData.Session.UserData.role,
            text: "Descargar archivo"
        }

        // Añadimos el tipo de fichero
        this.file.extension = this.getFileType();

        // Enviamos el mensaje con el fichero
        this.sendMessage("msgchat", true);

    }; */

  }

  processResponse(response) {

    if(response.resuelto !== undefined && response.resuelto === "OK") {
      
      // Creamos el objeto del fichero
      this.file = {
        type: "file",
        name: response.nombre_fichero,
        extension: response.extension_fichero,
        signedUrl: response.enlace_firmado,
        username: this.ots.sessionData.Session.UserData.name,
        role: this.ots.sessionData.Session.UserData.role,
        msg: ""
      }

      // Enviamos el mensaje
      this.sendMessage("msgchat", true);
      
    }

    else if(response.resuelto !== undefined && response.resuelto === "ER") {

      console.log("Error al enviar el mensaje");

    }

    else console.log("NOKNER");

  }

  getFileType() {

    var type = this.file.data.split(";");

    type = type[0].split("/")[1];

    if(type !== undefined) return type;
    else return "";

  }

  //sube el archivo a aws
  async uploadfile() {

    /* //si no hay fichero
    if (this.file === null) {
      return;
    }

    try {
      var s3 = new AWS.S3({ apiVersion: '2006-03-01' });
      var params = { Bucket: this.BUCKETAWS, Key: Date.now() + this.file.name, Body: this.file, ACL: 'public-read', };
      const data = await s3.upload(params).promise();

      //Ya esta subido, lo borro
      this.file = null;
      this.inputfile.nativeElement.value="";

      //ahora envio los datos al chat
      if (data.Location !== null) {
        this.session.signal({
          type: 'msgchat',
          data: JSON.stringify({
            type: 'file',
            msg: "",
            file: data,
            username: this.ots.sessionData.Session.UserData.name,
            role: this.ots.sessionData.Session.UserData.role
          })
        }, err => err && console.error(err));
      }
    }
    //en caso de error
    catch (err) {
      console.log(err);
    } */

  }

  getRol(){
    return this.ots.sessionData.Session.UserData.role;
  }


  //borra el fichero (este metodo se usa cuando el usuario le da a descargar)
  /* async deleteFile(file) {
    try {
      var s3 = new AWS.S3({ apiVersion: '2006-03-01' });
      var params = { Bucket: this.BUCKETAWS, Key: file };
      const data = await s3.deleteObject(params).promise();
    }
    catch (err) {
      console.log(err);
    }
  } */

  prepareMessage(textarea) {

    var text = textarea.value.trim();

    if(text != "") {

        this.messageData = {
            type: "text",
            msg: text,
            username: this.ots.sessionData.Session.UserData.name,
            role: this.ots.sessionData.Session.UserData.role
        }
    
        this.sendMessage("msgchat", false, textarea);

    }

  }

  //boton de enviar el mensaje
  sendMessageOld(textarea = false, sendfile: boolean) {

    /* if (sendfile) {
      this.uploadfile();
    }

    let text = textarea.value.trim();
    text = text.slice(0, 288);

    if (textarea.value.trim() !== '') {
      this.session.signal({
        type: 'msgchat',
        data: JSON.stringify({
          type: 'text',
          msg: text,
          username: this.ots.sessionData.Session.UserData.name,
          role: this.ots.sessionData.Session.UserData.role
        })
      }, err => err && console.error(err));
    }
    textarea.value = ''; */
  }

    sendMessage(type: string, file: boolean, textarea:any = false) {

        this.session.signal({
            type: type,
            data: file ? JSON.stringify(this.file) : JSON.stringify(this.messageData),
        }, err => err && console.error(err));

        if(textarea) textarea.value = '';

        if(file) this.textoBotonEnviar = "attach_file";

    }

    dragOverControl(valor) {

      this.dragOver = valor;

    }

}